import * as React from "react";

import PageContent from "../../components/StyledComponents/PageContent";
import PageLayout from "../../components/StyledComponents/PageLayout";
import PageTitle from "../../components/StyledComponents/PageTitle";
import Paragraph from "../../components/StyledComponents/Paragraph";
import CoreServicesList from "./CoreServicesList";

import { SERVICES_DATA } from "../../constants/servicesData";

function ServicePageContent(props) {
  const { pageType } = props;
  const {
    pageName,
    pageContent: { description, coreServices, coreServicesDescription },
  } = SERVICES_DATA[pageType] ?? { pageContent: {} };

  return (
    <PageLayout>
      <PageTitle variant="h2" component="h1">
        {pageName}
      </PageTitle>
      <PageContent>
        <Paragraph textProps={{ mb: 4 }}>{description}</Paragraph>
        <CoreServicesList
          items={coreServices}
          description={coreServicesDescription}
        />
      </PageContent>
    </PageLayout>
  );
}

export default ServicePageContent;
