import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuMUI from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import logo from "../../public/Logo.png";
import { styled } from "@mui/material";
import { SERVICES_DATA } from "../../constants/servicesData";
import ROUTE_PATHS from "../../constants/routes";

function Logo() {
  return (
    <Box>
      <a href="./" alt="home">
        <img src={logo} alt="security-tree-logo" style={{ width: "150px" }} />
      </a>
    </Box>
  );
}

const NavButton = styled(Button)(() => {
  return {
    height: "50px",
    color: "white",
    display: "block",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "inherit",
      fontWeight: 700,
    },
    "&.MuiButton-outlined, &.MuiButton-text": {
      borderRadius: "30px",
      padding: "10px 20px 8px",
      borderColor: "white",
      borderWidth: "1px",
      alignSelf: "baseline",
      "&.MuiButton-outlined:hover": {
        backgroundColor: "inherit",
        fontWeight: 700,
        textDecorationLine: "none",
        borderWidth: "2px",
      },
    },
    "&.MuiButton-outlined, span.MuiButton-endIcon": {
      display: "inline",
      "& svg": {
        verticalAlign: "text-top",
      },
    },
    "&.MuiButton-text:after": {
      content: '""',
      position: "absolute",
      width: "100%",
      transform: "scaleX(0)",
      height: "2px",
      bottom: 7,
      left: 0,
      backgroundColor: "white",
      transformOrigin: "bottom right",
      transition: "transform 0.25s ease-out",
    },
    "&.MuiButton-text:hover:after": {
      transform: "scaleX(1)",
      transformOrigin: "bottom left",
    },
  };
});

const Menu = styled(MenuMUI)(() => {
  return {
    "& ul": {
      backgroundColor: "#353c40",
      "& li:hover": {
        backgroundColor: "#555e60",
      },
      "& a": {
        color: "white",
        textDecoration: "none",
        width: "100%",
        display: "block",
        "&:hover p": {
          fontWeight: 700,
        },
      },
    },
  };
});

const services = Object.keys(SERVICES_DATA).map((serviceKey) => {
  const service = SERVICES_DATA[serviceKey];
  const { pageName, route } = service;
  return { pageName, route };
});

const pages = [
  {
    pageName: "About Us",
    route: ROUTE_PATHS.aboutUs,
  },
  {
    pageName: "Services",
    menu: services,
  },
  {
    pageName: "Why cybersecurity is important",
    route: ROUTE_PATHS.whyImportant,
    // menu: cybersecurityPages,
  },
  {
    pageName: "Blog",
    route: ROUTE_PATHS.blog,
  },
  {
    pageName: "Contacts",
    route: ROUTE_PATHS.contacts,
    variant: "outlined",
    styles: { mt: "-2px" },
  },
];

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  function handleOpenNavMenu(event) {
    setAnchorElNav(event.currentTarget);
  }

  function handleCloseNavMenu() {
    setAnchorElNav(null);
  }

  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [targetMenu, setTargetMenu] = React.useState(null);

  function handleOpenSubMenu(event, menu) {
    event.preventDefault();
    setAnchorElMenu(event.target);
    setTargetMenu(menu);
  }

  const handleCloseSubMenu = () => {
    setAnchorElMenu(null);
    setTargetMenu(null);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#252b2d", p: 1 }}>
      <Container>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Logo />
          <Box
            sx={{
              display: { lg: "flex", xs: "none" },
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            {pages.map((page) =>
              page.menu ? (
                <NavButton
                  key={page.pageName}
                  href={page.route}
                  onClick={(e) => {
                    handleOpenSubMenu(e, page.menu);
                  }}
                  endIcon={
                    anchorElMenu && targetMenu ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                >
                  {page.pageName}
                </NavButton>
              ) : (
                <NavButton
                  key={page.pageName}
                  href={page.route}
                  variant={page.variant ?? "text"}
                  sx={page.styles}
                >
                  {page.pageName}
                </NavButton>
              )
            )}
          </Box>
          {anchorElMenu && targetMenu ? (
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open
              onClose={handleCloseSubMenu}
            >
              {targetMenu.map((menuItem) => (
                <MenuItem key={menuItem.pageName} onClick={handleCloseSubMenu}>
                  <a href={menuItem.route}>
                    <Typography>{menuItem.pageName}</Typography>
                  </a>
                </MenuItem>
              ))}
            </Menu>
          ) : null}
          <Box sx={{ flexGrow: 0, display: { xs: "flex", lg: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
              }}
            >
              {pages.map((page) =>
                page.menu ? (
                  <MenuItem
                    key={page.pageName}
                    onClick={(e) => {
                      handleOpenSubMenu(e, page.menu);
                    }}
                  >
                    <a
                      href={page.route}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{page.pageName}</Typography>
                      <ListItemIcon>
                        {anchorElMenu && targetMenu ? (
                          <KeyboardArrowUpIcon sx={{ color: "white" }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ color: "white" }} />
                        )}
                      </ListItemIcon>
                    </a>
                  </MenuItem>
                ) : (
                  <MenuItem key={page.pageName} onClick={handleCloseSubMenu}>
                    <a href={page.route}>
                      <Typography>{page.pageName}</Typography>
                    </a>
                  </MenuItem>
                )
              )}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
