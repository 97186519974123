import * as React from "react";

import Box from "@mui/material/Box";

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";

function Layout() {
  return (
    <Box sx={{ backgroundColor: "#0E0D2C", minHeight: "100vh" }}>
      <Header />
      <Content />
      <Footer />
    </Box>
  );
}

export default Layout;
