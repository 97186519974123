import * as React from "react";
import Helmet from "react-helmet";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";

function SecureCodeReviewsView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Secure Code Reviews</title>
        <meta
          name="description"
          content="Our Secure Code Review services use a combination of manual and automated methods to identify security bugs early in the system development lifecycle. We provide clear and actionable remediation guidance to fix vulnerabilities and regularly test and monitor to ensure security. Our team focuses on secure coding practices and stays up-to-date with the latest security threats, vulnerabilities, and exploits. Contact us today to learn more about our services."
        />
        <meta
          name="keywords"
          content="Secure Code Review, security bugs, system development lifecycle, manual review, automated review, remediation guidance, secure coding practices, testing, monitoring, security threats, vulnerabilities, exploits."
        />
        <meta
          property="og:description"
          content="Our Secure Code Review services use a combination of manual and automated methods to identify security bugs early in the system development lifecycle. We provide clear and actionable remediation guidance to fix vulnerabilities and regularly test and monitor to ensure security. Our team focuses on secure coding practices and stays up-to-date with the latest security threats, vulnerabilities, and exploits. Contact us today to learn more about our services."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Secure Code Reviews"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/secure-code-reviews"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/secure-code-reviews"
        ></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.SECURE_CODE_REVIEWS} />
    </>
  );
}

export default SecureCodeReviewsView;
