import UnderstandingSsdlcPage from "../views/Blog/BlogArticlesPages/UnderstandingSsdlcPage";
import BeginnersGuidePage from "../views/Blog/BlogArticlesPages/BeginnersGuidePage";
import ExploringDarkWebPage from "../views/Blog/BlogArticlesPages/ExploringDarkWebPage";
import MobileBestPracticesPage from "../views/Blog/BlogArticlesPages/MobileBestPracticesPage";
import SocialEngineeringPage from "../views/Blog/BlogArticlesPages/SocialEngineeringPage";
import AttackOnIotDevicesPage from "../views/Blog/BlogArticlesPages/AttackOnIotDevicesPage";
import OsSecurityPatchManagementPage from "../views/Blog/BlogArticlesPages/OsSecurityPatchManagementPage";

import ssdlcIcon from "../public/blog/SSDLC.png";
import beginnersGuideIcon from "../public/blog/BeginnersGuide.png";
import exploringDarkWebIcon from "../public/blog/ExploringDarkWeb.png";
import mobileBestPracticesIcon from "../public/blog/MobileBestPractices.png";
import socialEngineeringIcon from "../public/blog/SocialEngineering.png";
import attackOnIotDevicesIcon from "../public/blog/AttackOnIotDevices.png";
import osSecurityPatchManagementIcon from "../public/blog/OsSecurityPatchManagement.png";

export const blogPages = {
  understandingSsdlc: {
    pageTitle: "Understanding SSDLC: What it is and why Your Business needs it",
    route: "understanding-ssdlc",
    view: <UnderstandingSsdlcPage />,
    icon: ssdlcIcon,
    timeStamp: "May 14, 2023, 7:16 PM",
    tags: [
      "SSDLC",
      "Secure software development",
      "Cybersecurity",
      "Data protection",
      "IT security",
      "Software security",
      "Business security",
    ],
  },
  beginnersGuide: {
    pageTitle:
      "Beginner's Guide: How to implement application security in Your Company",
    route: "beginners-guide",
    view: <BeginnersGuidePage />,
    icon: beginnersGuideIcon,
    timeStamp: "May 14, 2023, 7:16 PM",
    tags: [
      "Application security",
      "Cybersecurity",
      "Data protection",
      "Risk assessment",
      "Employee training",
      "Security tools",
      "Penetration testing",
      "Vulnerability assessment",
      "Security audits",
      "Business security",
      "Information security",
      "Small business security",
      "Enterprise security",
      "IT security",
      "Online security",
      "Data security",
      "Digital security",
      "Online privacy",
      "Internet security",
      "Information Protection",
      "Cyber threats",
      "Data breaches",
      "Hackers",
      "IT infrastructure",
      "Secure software",
      "Data management",
      "Network security",
      "Business Continuity",
    ],
  },
  exploringDarkWeb: {
    pageTitle: "Exploring the Dark Web",
    route: "exploring-dark-web",
    view: <ExploringDarkWebPage />,
    icon: exploringDarkWebIcon,
    timeStamp: "May 14, 2023, 7:16 PM",
    tags: [
      "Dark web",
      "Internet security",
      "Cyber safety",
      "Online privacy",
      "Digital security",
      "Darknet",
      "Deep web",
      "Malware",
      "Illegal activities",
      "Online threats",
      "Cyber awareness",
      "Cyber education",
      "Cyber risk management",
      "Web anonymity",
      "Internet exploration",
      "Web security",
    ],
  },
  mobileBestPractices: {
    pageTitle: "10 Best Practices for Securing Your Mobile Device",
    route: "mobile-best-practices",
    view: <MobileBestPracticesPage />,
    icon: mobileBestPracticesIcon,
    timeStamp: "May 16, 2023, 11:40 AM",
    tags: [
      "Mobile Device Security",
      "Device Updates",
      "Strong Passwords",
      "Two-factor authentication",
      "Public WiFi security",
      "Mobile security",
      "Data backup",
    ],
  },
  socialEngineering: {
    pageTitle: "Social Engineering: How to Recognize and Prevent Attacks",
    route: "social-engineering-recognize-and-prevent",
    view: <SocialEngineeringPage />,
    icon: socialEngineeringIcon,
    timeStamp: "May 23, 2023, 10:00 AM",
    tags: ["Social Engineering", "Phishing", "Sensitive information"],
  },
  attackOnIotDevices: {
    pageTitle: "Attacks on Internet of Things (IoT) Devices",
    route: "attacks-on-internet-of-things-devices",
    view: <AttackOnIotDevicesPage />,
    icon: attackOnIotDevicesIcon,
    timeStamp: "August 20, 2023, 10:00 PM",
    tags: ["IT infrastructure", "Phishing", "IOT"],
  },
  osSecurityPatchManagement: {
    pageTitle: "Operating system security and patch management",
    route: "operating-system-security-and-patch-management",
    view: <OsSecurityPatchManagementPage />,
    icon: osSecurityPatchManagementIcon,
    timeStamp: "August 28, 2023, 10:00 AM",
    tags: ["OS Security", "Patching", "Hardening"],
  },
};
