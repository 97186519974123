import * as React from "react";

import Typography from "@mui/material/Typography";

import { styled } from "@mui/material";

const PageTitleRoot = styled(Typography)(() => {
  return {
    "&": {
      fontSize: "3.5rem",
      borderBottom: "1px solid #e6e6e6",
      padding: "4rem 0 1.5rem",
    },
  };
});

function PageTitle(props) {
  const { children, ...other } = props;
  return (
    <PageTitleRoot variant="h2" component="h1" {...other}>
      {children}
    </PageTitleRoot>
  );
}

export default PageTitle;
