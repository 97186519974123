import * as React from "react";

import PageContent from "../../../../components/StyledComponents/PageContent";
import PageLayout from "../../../../components/StyledComponents/PageLayout";
import PageTitle from "../../../../components/StyledComponents/PageTitle";
import Paragraph from "../../../../components/StyledComponents/Paragraph";
import TagsBlock from "../../../../components/StyledComponents/TagsBlock";

import Typography from "@mui/material/Typography";

import { blogPages } from "../../../../constants/blogPages";

import Helmet from "react-helmet";
import BlogPageTopContent from "../../../../components/StyledComponents/BlogPageTopContent";

function BeginnersGuidePage() {
  const { pageTitle, icon, timeStamp, tags } = blogPages.exploringDarkWeb;

  const topTextContent = (
    <Paragraph>
      Have you ever heard of the Dark Web? It's a part of the internet that
      isn't indexed by search engines and requires specific software to access.
      While it may sound mysterious and intriguing, it's important to understand
      the potential dangers that come with exploring this part of the internet.
    </Paragraph>
  );

  return (
    <>
      <Helmet>
        <title>SecurityTree | Exploring the Dark Web</title>
        <meta name="description" content="" />
        <meta
          name="keywords"
          content="dark web, internet, search engines, specific software, potential dangers, illegal activities, drug trafficking, human trafficking, stolen personal information, anonymity, law enforcement, illegal exploration, caution, legal consequences, malware, malicious software, responsible exploration, online security, internet safety"
        />
        <meta property="og:description" content="" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Exploring the Dark Web"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/blog/exploring-dark-web"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/blog/exploring-dark-web"
        ></link>
      </Helmet>
      <PageLayout>
        <PageTitle>{pageTitle}</PageTitle>
        <PageContent>
          <BlogPageTopContent
            img={icon}
            alt={pageTitle}
            textContent={topTextContent}
          />
          <Paragraph>
            The Dark Web is often associated with illegal activities such as
            drug trafficking, human trafficking, and the sale of stolen personal
            information. It's a place where anonymity is prized, making it
            difficult for law enforcement to track down those responsible for
            these illegal activities.
          </Paragraph>
          <Paragraph>
            While not all activity on the Dark Web is illegal, it's important to
            exercise caution when exploring this part of the internet. Not only
            are there potential legal consequences, but there is also a risk of
            encountering malware and other malicious software.
          </Paragraph>
          <Paragraph>
            If you're interested in learning more about the Dark Web, it's
            important to do so responsibly. Educate yourself on the potential
            risks and take steps to protect your online security. Remember, just
            because something is accessible on the internet doesn't mean it's
            legal or safe.
          </Paragraph>
          <Paragraph>
            Stay safe and informed out there on the internet!
          </Paragraph>
          <Typography
            variant="caption"
            component="p"
            sx={{
              color: "rgba(0, 0, 0, 0.58)",
              mt: 6,
            }}
          >
            {timeStamp}
          </Typography>
          {tags?.length ? <TagsBlock tags={tags} /> : null}
        </PageContent>
      </PageLayout>
    </>
  );
}

export default BeginnersGuidePage;
