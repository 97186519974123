import * as React from "react";

import Typography from "@mui/material/Typography";

function Paragraph(props) {
  const { children, strong, textProps = {}, ...other } = props;
  return (
    <Typography
      gutterBottom
      sx={{
        fontWeight: strong ? 700 : 500,
        fontSize: "1.25rem",
        mt: strong ? 4 : 2,
        ...textProps,
      }}
      {...other}
    >
      {children}
    </Typography>
  );
}

export default Paragraph;
