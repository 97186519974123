import * as React from "react";
import Helmet from "react-helmet";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";

function SecurityRequirementsView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Structured Security Requirements</title>
        <meta
          name="description"
          content="Our Security Requirements practice focuses on creating structured security requirements for developer teams to ensure the security of your software application. Contact us today to learn more about our services."
        />
        <meta
          name="keywords"
          content="Security Requirements, Secure Software Development, High-level Security Objectives, Functional Requirements, Structured Security Requirements, Supplier Evaluation, Organizational Security Requirements"
        />
        <meta
          property="og:description"
          content="Our Security Requirements practice focuses on creating structured security requirements for developer teams to ensure the security of your software application. Contact us today to learn more about our services."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Structured Security Requirements"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/security-requirements"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/security-requirements"
        ></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.SECURITY_REQUIREMENTS} />
    </>
  );
}

export default SecurityRequirementsView;
