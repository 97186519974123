import * as React from "react";

import Grid from "@mui/material/Grid";

function BlogPageTopContent(props) {
  const { img, alt, textContent } = props;

  return (
    <Grid container sx={{ alignItems: "center" }}>
      <Grid item lg={3} md={6} xs={12} sx={{ paddingRight: "24px" }}>
        <img
          src={img}
          alt={alt}
          style={{
            alignSelf: "center",
            width: "100%",
            maxHeight: "230px",
          }}
        />
      </Grid>
      <Grid item lg={9} md={6} xs={12}>
        {textContent}
      </Grid>
    </Grid>
  );
}

export default BlogPageTopContent;
