import * as React from "react";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";
import Helmet from "react-helmet";

function OsintView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Open-Source Intelligence</title>
        <meta
          name="description"
          content="Stay ahead of the competition and reduce risks with our Open-Source Intelligence (OSINT) services. We collect and examine publicly available data from a variety of sources such as the internet, social media, and databases. Our core services include network security, business intelligence and competitive intelligence, reputation management, reputation restoration, identifying insider threats, and dark web monitoring. Contact us today to learn how our OSINT services can help your business grow."
        />
        <meta
          name="keywords"
          content="Open-Source Intelligence, OSINT, competitive analysis, risk assessment, background checks, network security, business intelligence, competitive intelligence, reputation management, reputation restoration, insider threat identification, dark web monitoring"
        />
        <meta
          property="og:description"
          content="Stay ahead of the competition and reduce risks with our Open-Source Intelligence (OSINT) services. We collect and examine publicly available data from a variety of sources such as the internet, social media, and databases. Our core services include network security, business intelligence and competitive intelligence, reputation management, reputation restoration, identifying insider threats, and dark web monitoring. Contact us today to learn how our OSINT services can help your business grow."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Open-Source Intelligence"
        />
        <meta property="og:url" content="https://securitytree.io/osint"></meta>
        <link rel="canonical" href="https://securitytree.io/osint"></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.OSINT} />;
    </>
  );
}

export default OsintView;
