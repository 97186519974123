import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

function PageLayout({ children }) {
  return (
    <Box sx={{ backgroundColor: "white", color: "#3A3B74", pt: "70px" }}>
      <Container
        sx={{
          minHeight: "500px",
          backgroundColor: "white",
        }}
      >
        {children}
      </Container>
    </Box>
  );
}

export default PageLayout;
