import * as React from "react";
import Helmet from "react-helmet";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";

function SecurityAndPenetrationTestingView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Security and Penetration Testing</title>
        <meta
          name="description"
          content="Our Security and Penetration Testing practice combines automated security testing tools with in-depth manual expert testing to uncover technical and business-logic weaknesses in applications. We establish a common security baseline, customize automated tests, and integrate them into the build and deploy process. Our experts perform manual security testing of high-risk components and conduct penetration testing to ensure comprehensive application testing. Contact us to learn more."
        />
        <meta
          name="keywords"
          content="security testing, penetration testing, automated security testing, manual security testing, application testing, vulnerability testing, cybersecurity, business-logic weaknesses, technical weaknesses, scalable baseline, automated testing tools, penetration testing services, cybersecurity company, contact us to learn more"
        />
        <meta
          property="og:description"
          content="Our Security and Penetration Testing practice combines automated security testing tools with in-depth manual expert testing to uncover technical and business-logic weaknesses in applications. We establish a common security baseline, customize automated tests, and integrate them into the build and deploy process. Our experts perform manual security testing of high-risk components and conduct penetration testing to ensure comprehensive application testing. Contact us to learn more."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Security and Penetration Testing"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/security-and-penetration-testing"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/security-and-penetration-testing"
        ></link>
      </Helmet>
      <ServicePageContent
        pageType={SERVICES.SECURITY_AND_PENETRATION_TESTING}
      />
    </>
  );
}

export default SecurityAndPenetrationTestingView;
