import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";

import logo from "../../public/Logo.png";
import { SERVICES_DATA } from "../../constants/servicesData";

import ROUTE_PATHS from "../../constants/routes";

function Logo(props) {
  return (
    <Box {...props}>
      <a href="./" alt="home">
        <img src={logo} alt="security-tree-logo" style={{ width: "200px" }} />
      </a>
    </Box>
  );
}

const RouterButton = styled(ListItemButton)(() => {
  return {
    "& li": {
      width: "auto",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
    "& span": {
      "&:hover": {
        fontWeight: "bold",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        width: "100%",
        transform: "scaleX(0)",
        height: "2px",
        bottom: 7,
        left: 0,
        backgroundColor: "white",
        transformOrigin: "bottom right",
        transition: "transform 0.25s ease-out",
      },
      "&:hover:after": {
        transform: "scaleX(1)",
        transformOrigin: "bottom left",
      },
    },
  };
});

const services = Object.keys(SERVICES_DATA).map((serviceKey) => {
  const service = SERVICES_DATA[serviceKey];
  const { pageName, route } = service;
  return { pageName, route };
});

function Footer() {
  return (
    <Box sx={{ backgroundColor: "#252b2d" }}>
      <Container sx={{ pt: 5, pb: 2, color: "white" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "calc(100% - 18px)",
              }}
            >
              <Box>
                <Logo sx={{ mb: 3 }} />
                <Typography variant="h5" gutterBottom>
                  About Us
                </Typography>
                <Divider
                  sx={{ borderColor: "rgba(255, 255, 255, 0.1)", mb: 3 }}
                />
                <Typography variant="body1" sx={{ pr: 1, lineHeight: "175%" }}>
                  We are a team of dedicated professionals. <br />
                  Our mission is to help businesses avoid potential losses and
                  bankruptcy due to cybersecurity deficiencies, to work with
                  confidence knowing that their sensitive data and operations
                  are protected.
                </Typography>
                {/* <Typography variant="h5" sx={{ mt: 5 }}>
                  Our Blog
                </Typography> */}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h5" gutterBottom>
              Our Services
            </Typography>
            <Divider
              sx={{ borderColor: "rgba(255, 255, 255, 0.1)", mb: 1.5 }}
            />
            <List>
              {services.map(({ route, pageName }) => {
                return (
                  <RouterButton
                    to={route}
                    sx={{ width: "100%", p: 0 }}
                    key={pageName}
                  >
                    <ListItem sx={{ pl: 0 }}>
                      <ListItemText primary={pageName} />
                    </ListItem>
                  </RouterButton>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ pb: 10 }}>
              <Typography variant="h5" gutterBottom>
                Contact Info
              </Typography>
              <Divider
                sx={{ borderColor: "rgba(255, 255, 255, 0.1)", mb: 3 }}
              />
              <a
                href="mailto: contact@securitytree.io"
                style={{
                  color: "white",
                  textDecoration: "none",
                  display: "flex",
                }}
              >
                <EmailIcon sx={{ pr: 1.5 }} />
                <Typography variant="body1">contact@securitytree.io</Typography>
              </a>
            </Box>
            <Box>
              <Typography variant="h5" gutterBottom>
                Socialize
              </Typography>

              <Divider
                sx={{ borderColor: "rgba(255, 255, 255, 0.1)", mb: 3 }}
              />
              <Box sx={{ display: "flex" }}>
                <a
                  href="https://twitter.com/security_tree"
                  title="Follow us on Twitter"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon fontSize="large" sx={{ color: "white" }} />
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Divider
          sx={{ borderColor: "rgba(255, 255, 255, 0.1)", mt: 3, mb: 2 }}
        />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            direction="row"
            spacing={4}
            divider={
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: "rgba(255, 255, 255, 0.1)" }}
              />
            }
          >
            <RouterButton
              to={ROUTE_PATHS.privacyPolicy}
              sx={{ p: 0, width: "auto" }}
              key={"Privacy Policy"}
            >
              <ListItem sx={{ pl: 0, pr: 0 }}>
                <ListItemText primary={"Privacy Policy"} />
              </ListItem>
            </RouterButton>
            <RouterButton
              to={ROUTE_PATHS.termsAndConditions}
              sx={{ p: 0 }}
              key={"Terms and Conditions"}
            >
              <ListItem sx={{ pl: 0, pr: 0 }}>
                <ListItemText primary={"Terms and Conditions"} />
              </ListItem>
            </RouterButton>
            <RouterButton
              to={ROUTE_PATHS.cookiePolicy}
              sx={{ p: 0 }}
              key={"Cookie Policy"}
            >
              <ListItem sx={{ pl: 0, pr: 0 }}>
                <ListItemText primary={"Cookies Policy"} />
              </ListItem>
            </RouterButton>
          </Stack>
        </Box>
      </Container>
      <Box sx={{ backgroundColor: "rgb(25, 29, 32)", py: 3 }}>
        <Typography variant="subtitle1" align="center" sx={{ color: "white" }}>
          &copy; SecurityTree 2023 - We grow your digital security
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
