import * as React from "react";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import ROUTE_PATHS from "../../../constants/routes";

function TagsBlock({ tags }) {
  return (
    <Box sx={{ mt: 8 }}>
      <Divider />
      <Grid container spacing={2} sx={{ my: 2, flexWrap: "nowrap" }}>
        <Grid item>
          <strong>Tags:</strong>
        </Grid>
        <Grid item>
          {tags.map((tag) => {
            return (
              <a
                key={tag}
                href={`${ROUTE_PATHS.blog}?search=${tag}`}
                style={{ paddingRight: "12px" }}
              >
                {tag}
              </a>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

export default TagsBlock;
