import * as React from "react";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";
import Helmet from "react-helmet";

function PhishingThreatView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Phishing Threat Simulation</title>
        <meta
          name="description"
          content="Protect your organization from phishing threats with our comprehensive phishing simulation and testing services. Identify vulnerabilities in your employees' knowledge and behavior with regards to phishing attacks. Reduce your largest attack surface by training and educating your employees. Customize your phishing campaigns and receive comprehensive reporting on each employee's actions. Contact us today to learn more."
        />
        <meta
          name="keywords"
          content="phishing simulation, phishing testing, employee training, vulnerability identification, data loss prevention, system compromise prevention, cybersecurity"
        />
        <meta
          property="og:description"
          content="Protect your organization from phishing threats with our comprehensive phishing simulation and testing services. Identify vulnerabilities in your employees' knowledge and behavior with regards to phishing attacks. Reduce your largest attack surface by training and educating your employees. Customize your phishing campaigns and receive comprehensive reporting on each employee's actions. Contact us today to learn more."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Phishing Threat Simulation"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/phishing-threat"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/phishing-threat"
        ></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.PHISHING_THREAT} />
    </>
  );
}

export default PhishingThreatView;
