import React from "react";

import {
  BrowserRouter as Router,
  Routes as RoutesRoot,
  Route,
} from "react-router-dom";
import { SERVICES_DATA } from "../constants/servicesData";
import ROUTE_PATHS from "../constants/routes";
import { blogPages } from "../constants/blogPages";

import Layout from "../layout";

const HomePage = React.lazy(function lazyLoad() {
  return import("../views/HomePage");
});

const AboutUs = React.lazy(function lazyLoad() {
  return import("../views/AboutUsPage");
});

const WhyIsImportant = React.lazy(function lazyLoad() {
  return import("../views/AboutCybersecurity/WhyCybersecurityIsImportantPage");
});

const WhatIncludes = React.lazy(function lazyLoad() {
  return import("../views/AboutCybersecurity/WhatDoesSecurityIncludesPage");
});

const ContactUs = React.lazy(function lazyLoad() {
  return import("../views/ContactUsPage");
});

const PrivacyPolicy = React.lazy(function lazyLoad() {
  return import("../views/PrivacyPolicyPage");
});

const TermsAndConditions = React.lazy(function lazyLoad() {
  return import("../views/TermsAndConditionsPage");
});

const CookiePolicy = React.lazy(function lazyLoad() {
  return import("../views/CookiePolicyPage");
});

const BlogSummary = React.lazy(function lazyLoad() {
  return import("../views/Blog/BlogSummaryPage");
});

const NotFound = React.lazy(function lazyLoad() {
  return import("../views/NotFoundPage");
});

function Routes() {
  return (
    <Router>
      <RoutesRoot>
        <Route path={ROUTE_PATHS.root} element={<Layout />}>
          <Route index path={ROUTE_PATHS.root} element={<HomePage />} />
        </Route>
        {Object.keys(SERVICES_DATA).map((serviceKey) => {
          const service = SERVICES_DATA[serviceKey];
          const { pageName, route, view } = service;
          return (
            <Route path={route} element={<Layout />} key={pageName}>
              <Route path={route} element={view} />
            </Route>
          );
        })}
        <Route path={ROUTE_PATHS.aboutUs} element={<Layout />}>
          <Route path={ROUTE_PATHS.aboutUs} element={<AboutUs />} />
        </Route>
        <Route path={ROUTE_PATHS.whyImportant} element={<Layout />}>
          <Route path={ROUTE_PATHS.whyImportant} element={<WhyIsImportant />} />
        </Route>
        <Route path={ROUTE_PATHS.whatIncludes} element={<Layout />}>
          <Route path={ROUTE_PATHS.whatIncludes} element={<WhatIncludes />} />
        </Route>
        <Route path={ROUTE_PATHS.contacts} element={<Layout />}>
          <Route path={ROUTE_PATHS.contacts} element={<ContactUs />} />
        </Route>
        <Route path={ROUTE_PATHS.privacyPolicy} element={<Layout />}>
          <Route path={ROUTE_PATHS.privacyPolicy} element={<PrivacyPolicy />} />
        </Route>
        <Route path={ROUTE_PATHS.termsAndConditions} element={<Layout />}>
          <Route
            path={ROUTE_PATHS.termsAndConditions}
            element={<TermsAndConditions />}
          />
        </Route>
        <Route path={ROUTE_PATHS.cookiePolicy} element={<Layout />}>
          <Route path={ROUTE_PATHS.cookiePolicy} element={<CookiePolicy />} />
        </Route>
        <Route path={ROUTE_PATHS.blog} element={<Layout />}>
          <Route path={ROUTE_PATHS.blog} element={<BlogSummary />} />
        </Route>
        {Object.keys(blogPages).map((pageKey) => {
          const page = blogPages[pageKey];
          const { pageTitle, route, view } = page;
          return (
            <Route
              path={`${ROUTE_PATHS.blog}/${route}`}
              element={<Layout />}
              key={pageTitle}
            >
              <Route path={`${ROUTE_PATHS.blog}/${route}`} element={view} />
            </Route>
          );
        })}
        <Route path={ROUTE_PATHS.notFound} element={<Layout />}>
          <Route path={ROUTE_PATHS.notFound} element={<NotFound />} />
        </Route>
      </RoutesRoot>
    </Router>
  );
}

export default Routes;
