import * as React from "react";

import Box from "@mui/material/Box";
import { styled } from "@mui/material";

const PageContentRoot = styled(Box)(() => {
  return {
    "&": {
      paddingTop: "1.5rem",
      paddingBottom: "3.5rem",
      fontSize: "1.25rem",
    },
  };
});

function PageContent({ children }) {
  return <PageContentRoot>{children}</PageContentRoot>;
}

export default PageContent;
