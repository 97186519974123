import * as React from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemMUI from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemTextMUI from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircleIcon from "@mui/icons-material/Circle";

const PRIMARY_ITEM_ICON = <ArrowRightIcon fontSize="large" />;
const SECONDARY_ITEM_ICON = <CircleIcon sx={{ fontSize: "8px" }} />;

const ListItemText = styled(ListItemTextMUI)(() => {
  return {
    "& span.MuiListItemText-primary": {
      fontSize: "inherit",
      color: "inherit",
    },
  };
});

function ListItem(props) {
  const { item, icon, inset } = props;
  return (
    <ListItemMUI disablePadding sx={{ pl: inset ? "60px" : 0 }}>
      <ListItemIcon
        style={{ color: "#3A3B74", minWidth: inset ? "36px" : "52px" }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText>{item}</ListItemText>
    </ListItemMUI>
  );
}

function CoreServicesList({ description, items }) {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 500, fontSize: "20px" }}
      >
        Core Services:
      </Typography>
      <Typography variant="body2" sx={{ fontSize: "16px", paddingBottom: 1.5 }}>
        {description}
      </Typography>
      <List style={{ color: "#3A3B74" }}>
        {items.map(({ listItem, icon: primaryIcon, subItems }) => {
          const listItemIcon = primaryIcon ?? PRIMARY_ITEM_ICON;
          return (
            <Box
              sx={{ mb: 2 }}
              key={`${listItem.substring(0, 100)}-listItemBox`}
            >
              <ListItem
                item={listItem}
                icon={listItemIcon}
                key={`${listItem.substring(0, 100)}-item`}
              />
              {subItems?.length
                ? subItems.map(({ subItem, icon: secondaryIcon }) => {
                    const subItemIcon = secondaryIcon ?? SECONDARY_ITEM_ICON;
                    return (
                      <ListItem
                        item={subItem}
                        icon={subItemIcon}
                        inset
                        key={`${subItem.substring(0, 100)}-item`}
                      />
                    );
                  })
                : null}
            </Box>
          );
        })}
      </List>
    </>
  );
}

export default CoreServicesList;
