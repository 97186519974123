import * as React from "react";
import Helmet from "react-helmet";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";

function SecurityArchitectureView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Security Architecture Practice</title>
        <meta
          name="description"
          content="Our Security Architecture practice focuses on securing the components and technologies used in the architectural design of your software. We offer Secure Architecture Design, training on basic security principles, and technology management services to ensure the security of supporting technologies. Contact us for more information."
        />
        <meta
          name="keywords"
          content="Security Architecture, Secure Architecture Design, technology management, secure design patterns, software security, development stacks, deployment tooling, operating systems, security solutions, technology standardization"
        />
        <meta
          property="og:description"
          content="Our Security Architecture practice focuses on securing the components and technologies used in the architectural design of your software. We offer Secure Architecture Design, training on basic security principles, and technology management services to ensure the security of supporting technologies. Contact us for more information."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Security Architecture Practice"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/security-architecture"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/security-architecture"
        ></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.SECURITY_ARCHITECTURE} />
    </>
  );
}

export default SecurityArchitectureView;
