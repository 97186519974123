import * as React from "react";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";
import Helmet from "react-helmet";

function SecureBuildView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Secure Build Practice</title>
        <meta
          name="description"
          content="The Secure Build practice emphasizes building software in a standardized, repeatable manner using secure components. Our core services include defining a formal build process, adding security checks in the build pipeline, and tracking the security status of software dependencies. Contact us to learn more about our secure build practices and how we can help protect your applications from security vulnerabilities."
        />
        <meta
          name="keywords"
          content="secure build, automated build pipeline, SAST, DAST, software dependencies, bill of materials, security checks"
        />
        <meta
          property="og:description"
          content="The Secure Build practice emphasizes building software in a standardized, repeatable manner using secure components. Our core services include defining a formal build process, adding security checks in the build pipeline, and tracking the security status of software dependencies. Contact us to learn more about our secure build practices and how we can help protect your applications from security vulnerabilities."
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="SecurityTree | Secure Build Practice"
        />
        <meta
          property="og:url"
          content="https://securitytree.io/secure-build"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/secure-build"
        ></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.SECURE_BUILD} />
    </>
  );
}

export default SecureBuildView;
