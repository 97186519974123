import * as React from "react";
import Helmet from "react-helmet";

import ServicePageContent from "../../../components/ServicePageContent";
import { SERVICES } from "../../../constants/servicesData";

function SecureDeploymentView() {
  return (
    <>
      <Helmet>
        <title>SecurityTree | Secure Deployment</title>
        <meta
          name="description"
          content="Ensure the security and integrity of your software during deployment with our Secure Deployment services. Contact us today to learn more about our automated deployment process, security verification tests, and secret management techniques."
        />
        <meta
          name="keywords"
          content="Secure Deployment, automated deployment, security verification, secret management, software deployment, software security, cybersecurity"
        />
        <meta
          property="og:description"
          content="Ensure the security and integrity of your software during deployment with our Secure Deployment services. Contact us today to learn more about our automated deployment process, security verification tests, and secret management techniques."
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="SecurityTree | Secure Deployment" />
        <meta
          property="og:url"
          content="https://securitytree.io/secure-deployment"
        ></meta>
        <link
          rel="canonical"
          href="https://securitytree.io/secure-deployment"
        ></link>
      </Helmet>
      <ServicePageContent pageType={SERVICES.SECURE_DEPLOYMENT} />
    </>
  );
}

export default SecureDeploymentView;
