import globe from "../public/Globe.svg";
import graph from "../public/Graph.svg";
import checkList from "../public/CheckList.svg";
import checkList2 from "../public/CheckList2.svg";
import document from "../public/Document.svg";
import document2 from "../public/Document2.svg";
import lock from "../public/Lock.svg";
import lockBox from "../public/LockBox.svg";
import safeDeposit from "../public/SafeDeposit.svg";

import SecurityArchitectureView from "../views/services/SecurityArchitectureView";
import SecurityRequirementsView from "../views/services/SecurityRequirementsView";
import SecureBuildView from "../views/services/SecureBuildView";
import SecureDeploymentView from "../views/services/SecureDeploymentView";
import SecureCodeReviewsView from "../views/services/SecureCodeReviewsView";
import RequirementsDrivingTestingView from "../views/services/RequirementsDrivingTestingView";
import SecurityAndPenetrationTestingView from "../views/services/SecurityAndPenetrationTestingView";
import PhishingThreatView from "../views/services/PhishingThreatView";
import OsintView from "../views/services/OsintView";

export const SERVICES = {
  SECURITY_ARCHITECTURE: "SECURITY_ARCHITECTURE",
  SECURITY_REQUIREMENTS: "SECURITY_REQUIREMENTS",
  SECURE_BUILD: "SECURE_BUILD",
  SECURE_DEPLOYMENT: "SECURE_DEPLOYMENT",
  SECURE_CODE_REVIEWS: "SECURE_CODE_REVIEWS",
  REQUIREMENTS_DRIVEN_TESTING: "REQUIREMENTS_DRIVEN_TESTING",
  PHISHING_THREAT: "PHISHING_THREAT",
  SECURITY_AND_PENETRATION_TESTING: "SECURITY_AND_PENETRATION_TESTING",
  OSINT: "OSINT",
};

export const SERVICES_DATA = {
  SECURITY_ARCHITECTURE: {
    pageName: "Security Architecture",
    route: "/security-architecture",
    view: <SecurityArchitectureView />,
    card: {
      imgSrc: document2,
      content:
        "Software requires a secure architectural design. Security architecture is concerned with the security of all aspects and methods during application development.",
    },
    pageContent: {
      description:
        "The Security Architecture practice focuses on the security linked to components and technology you deal with during the architectural design of your software.",
      coreServices: [
        {
          listItem:
            "Secure Architecture Design looks at the selection and composition of components that form the foundation of your solution, focusing on its security properties.",
          subItems: [
            {
              subItem:
                "Training teams on the use of basic security principles during design",
            },
            {
              subItem:
                "Establishing common design patterns and security solutions for adoption",
            },
          ],
        },
        {
          listItem:
            "Technology management looks at the security of supporting technologies used during development, deployment, and operations, such as development stacks and tooling, deployment tooling, and operating systems and tooling.",
          subItems: [
            {
              subItem:
                "Elicitation technologies, frameworks, and integrations within the overall solution",
            },
            {
              subItem:
                "Standardizing technologies and frameworks to be used throughout the different applications",
            },
            {
              subItem:
                "Imposing the use of standard technologies on all software development",
            },
          ],
        },
      ],
    },
  },
  SECURITY_REQUIREMENTS: {
    pageName: "Security Requirements",
    route: "/security-requirements",
    view: <SecurityRequirementsView />,
    card: {
      imgSrc: document,
      content:
        "The security of an application depends entirely on what rules, goals, and expectations have been set to protect service data and the application core.",
    },
    pageContent: {
      description:
        "It is important to streamline the expectations in terms of secure development because outsourced development can have significant impact on the security of the application. The Security Requirements practice focuses on security requirements that are important in the context of secure software. Software requirements, to specify objectives and expectations to protect the service and data at the core of the application.",
      coreServices: [
        {
          listItem:
            "Mapping high-level application security objectives to functional requirements",
        },
        {
          listItem:
            "Creating structured security requirements to utilize by developer teams",
        },
        {
          listItem:
            "Evaluation the supplier based on organizational security requirements",
        },
      ],
    }
  },
  SECURE_BUILD: {
    pageName: "Secure Build",
    route: "/secure-build",
    view: <SecureBuildView />,
    card: {
      imgSrc: lockBox,
      content:
        "Secure Build explains the importance of building software in a standardized, repeatable way and why you should use secure components.",
    },
    pageContent: {
      description:
        "The Secure Build practice emphasizes the importance of building software in a standardized, repeatable manner, and of doing so using secure components, including 3rd party software dependencies.",
      coreServices: [
        {
          listItem:
            "Build Process - focuses on removing any subjectivity from the build process by striving for full automation. An automated build pipeline can include additional automated security checks such as SAST and DAST to gain further assurance and flag security regressions early by failing the build.",
          subItems: [
            {
              subItem:
                "Consulting on creating a formal definition of the build process so that it becomes consistent and repeatable",
            },
            {
              subItem:
                "Evaluation of automation your build pipeline and secure the used tooling. Add security checks in the build pipeline",
            },
            {
              subItem:
                "Defining mandatory security checks in the build process and ensure that building non-compliant artifacts fails",
            },
          ],
        },
        {
          listItem:
            "Software Dependencies – acknowledges the prevalence of software dependencies in modern applications. It aims to identify them and track their security status in order to contain the impact of their insecurity on an otherwise secure application. In an advanced form, it applies similar security checks to software dependencies as to the application itself.",
          subItems: [
            {
              subItem:
                "Creation records with Bill of Materials of your applications",
            },
            {
              subItem:
                "Evaluation used dependencies and ensuring timely reaction to situations posing risk to your applications",
            },
            {
              subItem:
                "Analyzing used dependencies for security issues in a comparable way to your own code",
            },
          ],
        },
      ],
    }
  },
  SECURE_DEPLOYMENT: {
    pageName: "Secure Deployment",
    route: "/secure-deployment",
    view: <SecureDeploymentView />,
    card: {
      imgSrc: safeDeposit,
      content: "Secure Deployment in delivering secure software is ensuring the security and integrity of developed applications are not compromised during deployment.",
    },
    pageContent: {
      description:
        "Secure Deployment in delivering secure software is ensuring the security and integrity of developed applications are not compromised during deployment.",
      coreServices: [
        {
          listItem:
            "Deployment Process focuses on removing manual error by automating the deployment process as much as possible and making its success contingent upon the outcomes of integrated security verification checks. ",
          subItems: [
            {
              subItem:
                "Formalizing the deployment process and secure the used tooling and processes",
            },
            {
              subItem:
                "Consulting on automation the deployment process over all stages and introduce sensible security verification tests",
            },
          ],
        },
        {
          listItem:
            "Secret Management goes beyond the mechanics of deployment and focuses on protecting the privacy and integrity of sensitive data, such as passwords, tokens, and other secrets, required for applications to operate in production environments. In its simplest form, suitable production secrets are moved from repositories and configuration files into managed digital vaults. In more advanced forms, secrets are dynamically generated at deployment time and routine processes detect and mitigate the presence of any unprotected secrets in the environment.",
          subItems: [
            {
              subItem:
                "Introducing basic protection measures to limit access to your production secrets",
            },
            {
              subItem:
                "Evaluation of the inject secrets dynamically during deployment process from hardened storages and audit all human access to them",
            },
            {
              subItem:
                "Improving the lifecycle of application secrets by regularly generating them and by ensuring proper use",
            },
          ],
        },
      ],
    }
  },
  SECURE_CODE_REVIEWS: {
    pageName: "Secure Code Reviews",
    route: "/secure-code-reviews",
    view: <SecureCodeReviewsView />,
    card: {
      imgSrc: lock,
      content: "Secure Code Review is the effective technique for identifying security bugs early in the development lifecycle. When used together with automated and manual penetration testing, it can significantly increase the cost effectiveness of a verification effort.",
    },
    pageContent: {
      description:
        "Secure Code Review is the effective technique for identifying security bugs early in the system development lifecycle. When used together with automated and manual penetration testing, code review can significantly increase the cost effectiveness of an application security verification effort.",
      coreServices: [
        {
          listItem:
            "Using a combination of manual and automated code review methods",
        },
        {
          listItem:
            "Providing clear and actionable remediation guidance: once vulnerabilities are identified, we provide clear and actionable guidance to the development team on how to fix the issues",
        },
        {
          listItem:
            "Providing regular testing and monitoring: Continuous testing should be done, to ensure that the vulnerabilities have been correctly fixed, and that new vulnerabilities don 't appear over time",
        },
        {
          listItem:
            "Focusing on secure coding practices: we help organizations to implement secure coding practices",
        },
        {
          listItem:
            "Keeping an eye on the latest security threats: we have a good understanding of the current threat landscape and familiar with the latest security threats, vulnerabilities and exploit.",
        },
      ],
    }
  },
  REQUIREMENTS_DRIVEN_TESTING: {
    pageName: "Requirements-driven testing",
    route: "/requirements-driven-testing",
    view: <RequirementsDrivingTestingView />,
    card: {
      imgSrc: checkList,
      content: "Security controls are an important part of software security. A set of tests and regression cases should be implemented and run regularly to make sure that security controls are working as planned for the project.",
    },
    pageContent: {
      description:
        "The goal of the Requirements-driven Testing practice is to ensure that the implemented security controls operate as expected and satisfy the project’s stated security requirements. It does so by incrementally building a set of security test and regression cases and executing them regularly.",
      coreServicesDescription:
        "A key aspect of this practice is its attention to both positive and negative testing. The former verifies that the application’s security controls satisfy stated security requirements and validates their correct functioning. These requirements are typically functional in nature. Negative testing addresses the quality of the implementation of the security controls and aims to detect unexpected design flaws and implementation bugs through misuse and abuse testing. In its more advanced forms, the practice promotes security stress testing, such as denial of service, and strives to continuously improve application security by consistently automating security unit tests and creating security regression tests for all bugs identified and fixed.",
      coreServices: [
        {
          listItem: "Testing for software security controls",
        },
        {
          listItem: "Deriving test cases from known security requirements",
        },
        {
          listItem: "Performing security fuzzing testing",
        },
        {
          listItem: "Creation and test abuse cases and business logic flaw test",
        },
        {
          listItem: "Denial of service and security stress testing",
        },
      ],
    }
  },
  PHISHING_THREAT: {
    pageName: "Phishing Threat",
    route: "/phishing-threat",
    view: <PhishingThreatView />,
    card: {
      imgSrc: graph,
      content: "Periodically assessing staff readiness for Phishing Threats is crucial for minimizing the risk of costly data breaches and financial losses.",
    },
    pageContent: {
      description:
        "The use of fishing simulation is a powerful way for companies to improve the overall security of their organization. It allows them to identify and address vulnerabilities in employees ' knowledge and behavior, which can be used to prevent data loss and system compromise caused by phishing attacks.",
      coreServicesDescription:
        "Phishing simulation and testing: sending simulated phishing emails to employees and monitoring their actions, to identify vulnerabilities in their knowledge and behavior with regards to phishing attacks.",
      coreServices: [
        {
          listItem: "Reduce your largest attack surface - you employees",
        },
        {
          listItem: "The freshest and customizable phishing campaigns",
        },
        {
          listItem: "Comprehensive reporting on each employee",
        },
      ],
    }
  },
  SECURITY_AND_PENETRATION_TESTING: {
    pageName: "Security and Penetration Testing",
    route: "/security-and-penetration-testing",
    view: <SecurityAndPenetrationTestingView />,
    card: {
      imgSrc: checkList2,
      content: "Penetration Testing is an important aspect of the software development process as it helps to identify and remediate vulnerabilities before hackers can exploit them.",
    },
    pageContent: {
      description:
        "The Security Testing practice leverages the fact that, while automated security testing is fast and scales well to numerous applications, in-depth testing based on good knowledge of an application and its business logic is often only possible via slower, manual expert security testing. Unlike the Requirements-driven testing practice which focuses on verifying that applications correctly implement their requirements, the goal of this practice is to uncover technical and business-logic weaknesses in application and make them visible to management and business stakeholders, irrespective of requirements.",
      coreServices: [
        {
          listItem:
            "Scalable Baseline focuses on establishing a common security baseline to automatically detect so-called “low hanging fruit.” Progressively customize the automated tests for each application and increase their frequency of execution to detect more bugs and regressions earlier, as close as possible to their inception.",
          subItems: [
            {
              subItem: "Utilize automated security testing tools",
            },
            {
              subItem: "Employ application-specific security testing automation",
            },
            {
              subItem:
                "Integrate automated security testing into the build and deploy process",
            },
          ],
        },
        {
          listItem:
            "The more bugs the automated processes can detect, the more time experts have to use their knowledge and creativity to focus on more complex attack vectors and ensure in-depth application testing in Deep Understanding practice. As manual review is slow and hard to scale, reviewers prioritize testing components based on their risk, recent relevant changes, or upcoming major releases.",
          subItems: [
            {
              subItem: "Perform manual security testing of high-risk components",
            },
            {
              subItem: "Conduct manual penetration testing",
            },
          ],
        },
      ],
    }
  },
  OSINT: {
    pageName: "Open-Source Intelligence (OSINT)",
    route: "/osint",
    view: <OsintView />,
    card: {
      imgSrc: globe,
      content: "Open Source Intelligence allows businesses to remain competitive and reduce risks by collecting and examining publicly available data from a variety of sources such as the internet, social media, and databases.",
    },
    pageContent: {
      description:
        "Open-Source Intelligence allows businesses to remain competitive and reduce risks by collecting and examining publicly available data from a variety of sources such as the internet, social media, and databases. OSINT can provide the necessary information for competitive analysis, risk assessment, and background checks to help your businesses grow.",
      coreServices: [
        {
          listItem:
            "Network security: information gathering about an organization's online presence, including its domain names, IP addresses, and other publicly available information that can be used to identify potential vulnerabilities in the network.",
        },
        {
          listItem:
            "Business Intelligence and Competitive Intelligence: information gathering about a company's competitors and the market trends which can be useful for the growth and development of the business.",
        },
        {
          listItem:
            "Reputation Management: supporting ongoing reputation management efforts by identifying positive mentions and building relationships with key influencers and media outlets, and improving the company's online presence.",
        },
        {
          listItem:
            "Reputation restoration: supporting reputation restoration efforts by identifying the sources of negative information and helping the company to respond, mitigate or refute false information.",
        },
        {
          listItem:
            "Identifying Insider Threats: OSINT can help to identify employees who may pose an insider threat to the organization.",
        },
        {
          listItem:
            "Dark web monitoring: monitoring dark web marketplaces and forums where stolen data and hacking tools are often sold. This can help get early warning and take proactive actions on potential breaches.",
        },
      ],
    },
  }
};
