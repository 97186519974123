const ROUTE_PATHS = {
  root: "/",
  aboutUs: "/about-us",
  whyImportant: "/why-cybersecurity-is-important",
  whatIncludes: "/what-does-security-includes",
  contacts: "/contact-us",
  privacyPolicy: "/privacy-policy",
  termsAndConditions: "/terms-and-conditions",
  cookiePolicy: "/cookie-policy",
  blog: "/blog",
  notFound: "*",
};

export default ROUTE_PATHS;
